import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccordionHeader from "../components/v2/AccordionHeader";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import syncEmailsIcon from "../images/v2/inbox/sync-emails.svg";
import workFromEmailIcon from "../images/v2/inbox/chrome-extension.svg";
import syncContactIcon from "../images/v2/inbox/access-contacts.svg";
import featureOrganize from "../images/v2/inbox/Organize.svg";
import featureTrasform from "../images/v2/inbox/Transform.svg";
import featureUnderstand from "../images/v2/inbox/Understand.svg";
import integrationsFeaturesBg from "../images/v2/integration/integration-features-bg.svg";
import useWidth from "../hooks/useWidth";
import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import inboxBannerBgImg from "../images/v2/home/integrated-crm-bg.svg";
import twoWaySyncImg from "../images/v2/inbox/two-way-sync.svg";
import workFromEmailImg from "../images/v2/inbox/gmail-chrome-extension.svg";
import inboxImg1 from "../images/v2/inbox/Inbox-1@2x.svg";
import inboxImg3 from "../images/v2/inbox/Inbox-3@2x.svg";
import SEO from "../components/SEO";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
	img: {
		height: "auto",
		maxWidth: "100%",
	},
	integrationsFeatures: {
		background: `url(${integrationsFeaturesBg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	integrationFeatureItem: {
		border: "1px solid",
		background: "#fff",
		borderRadius: theme.shape.borderRadius * 2.5,
		borderColor: "#e2f0ff",
		padding: theme.spacing(3),
		boxShadow: "10px 20px 20px 0 rgba(0, 0, 0, 0.1)",
		"&:hover": {
			background: "#f2f9f9",
			borderColor: "#f2f9f9",
		},
	},
	requestDemoRoot: {
		background: `url(${requestDemoBGImg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	expendButton: {
		backgroundColor: "#eaf0f6",
		"&:hover": {
			background: "#a1dae2",
		},
	},
	testimonialCustomerImg: {
		maxWidth: "90%",
		width: "290px",
		borderRadius: "10px",
		boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
		marginTop: "2.65rem",
		marginLeft: "3rem",
	},
	testimonialBg: {
		background: `url(${testimonialBgImg}) no-repeat`,
		backgroundSize: "195px 223px",
	},
	inboxBannerImgBg: {
		background: `url(${inboxBannerBgImg}) center bottom`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
}));

const client = {
	image: (
		<StaticImage
			alt="Pepper Cloud+TechData Corporation"
			layout="constrained"
			src="../images/v2/testimonials-images/yoon-kam-fei-peppercloud-customer.png"
		/>
	),
	name: "Yoon Kam Fei",
	position: "Managing Director",
	company: "Tec D Distribution Sdn Bhd, Malaysia",
	content: (
		<React.Fragment>
			Team collaboration, accurate sales forecasting, and effective resource management are the challenges every
			organisation encounters. But having an efficient CRM tool like Pepper Cloud enhances productivity while assisting
			in optimising the sales process and managing the sales pipeline.
			<br />
			<br />
			Pepper Cloud CRM tool is the right mix of flexibility, affordability, and ease of use.
		</React.Fragment>
	),
};

const Inbox = () => {
	const width = useWidth();
	const classes = useStyles();
	const [activeSyncEmails, setActiveSyncEmails] = React.useState(0);
	const [activeSyncContact, setActiveSyncContact] = React.useState(0);
	const [activeWorkFromEmail, setActiveWorkFromEmail] = React.useState(0);
	const syncEmails = [
		{
			title: "Sync emails in minutes",
			description:
				"Instantly update your CRM with sales data. With email integration, access the emails sent from Google mail or Office 365 from your CRM in real-time and gather the context around the conversation.",
		},
		{
			title: "No switching between tabs",
			description: (
				<>
					Sending emails is an integral part of the sales process. With seamless{" "}
					<a href="https://peppercloud.com/integration">CRM system integration</a> with Office 365 or Gmail inbox, send
					out emails to your customers right from your inbox, without having the need to switch to your email provider.
				</>
			),
		},
		{
			title: "Inbox in real-time",
			description:
				"Converse with your customers in a snap and fast track your sales cycle. Your CRM inbox refreshes automatically and saves you from missing any important conversations. With the best CRM for Office 365 and Gmail, create emails, send replies, and build your sales strategy effortlessly right from your CRM software.",
		},
		{
			title: "Stay connected",
			description:
				"Never lose records of sales conversations. Email integration allows you to access all the relevant emails from the CRM platform even after disintegrating Office 365 or Gmail inbox from your account.",
		},
	];
	const workFromEmail = [
		{
			title: "Automate contact integration",
			description:
				"We know that you need your CRM all the time. The email integration and Chrome extension do just that, for you. It works seamlessly in the background to automatically sync your sales conversations from Gmail inbox to your CRM and allows you to access your emails wherever you go.",
		},
		{
			title: "Log emails to your inbox from Gmail",
			description:
				"We know that you need your CRM all the time. The email integration and Chrome extension do just that, for you. It works seamlessly in the background to automatically sync your sales conversations from Gmail inbox to your CRM and allows you to access your emails wherever you go.",
		},
		{
			title: "Assign tasks and streamline sales activities",
			description: (
				<>
					Stay on top of your schedule and keep track of your tasks even when you are away from CRM software. Perform
					your{" "}
					<a href="https://blog.peppercloud.com/sales-activities-building-blocks-of-sales-process/">
						{" "}
						sales activities
					</a>
					, and assign tasks to your team effortlessly through Gmail inbox, with our powerful CRM chrome extension.
				</>
			),
		},
		{
			title: "Hassle-free access to complete information of account",
			description: (
				<>
					With our chrome extension, enjoy the power of accessing the right information at the right time.
					<a href="https://blog.peppercloud.com/account-management-made-easy/">
						{" "}
						View all the details of your customers and their accounts{" "}
					</a>{" "}
					whenever you need and customise your communication with the customers.
				</>
			),
		},
	];
	const syncContact = [
		{
			title: "Import contacts",
			description:
				"Access your contacts right from your CRM. With quick and easy contact import, sync all your contacts or just the selected contacts from Google or Office 365 in minutes. Segment them as leads or contacts in the CRM with a single click.",
		},
		{
			title: "Customised field mapping",
			description:
				"Easy drag-and-drop feature to map the fields of your contacts. Choose the default contact fields or customise them as per your requirements. Sync the Office 365 or Gmail contacts automatically, without any manual interventions.",
		},
		{
			title: "Incorporate complete details",
			description:
				"Help your sales team by providing them with comprehensive contact records. Import Google contacts or Office 365 contacts with their available display pics or avatars and be familiar with whom you are communicating.",
		},
	];
	return (
		<React.Fragment>
			<SEO
				canonical="/inbox"
				description="Send, manage, and track sales emails right from your CRM. Pepper Cloud's CRM email integration is compatible with your favorite email inboxs - Gmail, Outlook, Office 365, etc."
				keywords="crm email integration,Gmail integration,Outlook integration,best CRM for Office 365,CRM chrome extension,email integration, crm system integration"
				ogData={{
					"og:image": ["/meta/og/1X1/inbox.png", "/meta/og/1024X512/inbox.png", "/meta/og/1200X630/inbox.png"],
				}}
				pathname="/inbox"
				title="The best CRM email integration software | Pepper Cloud"
			/>
			<Box className={classes.inboxBannerImgBg}>
				<Container>
					<Box display="flex" justifyContent="center">
						<Box maxWidth={858} py={4} textAlign="center">
							<HeaderTypography component="h1" mb={4} mt={{ md: 6 }}>
								Email integration to supercharge your sales
							</HeaderTypography>
							<ParagraphTypography fontSize={18}>
								Transform the way you sell. Integrate Office 365 or Gmail mailbox with your CRM tool and save your time
								while doing your daily tasks by accessing all your emails and contacts directly from your CRM platform.
							</ParagraphTypography>
							<Box mb={4} />
							<Button
								className={classes.bookNowButton}
								color="secondary"
								component={Link}
								size="large"
								to="/contact"
								variant="contained"
							>
								Show me how
							</Button>
						</Box>
					</Box>
					<img
						alt="Visual of CRM inbox that syncs with Google mail, Google drive, and Office 365"
						className={classes.img}
						src={inboxImg1}
					/>
				</Container>
			</Box>
			<Container>
				<Grid alignItems="center" container justify="center">
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent="center">
							<Box maxWidth={650} mt={4} p={1}>
								<AccordionHeader
									alt="Gmail inbox"
									description={
										<>
											The way you communicate with your customers matters. With 2-way email integration, log customer
											communications automatically, analyse the context, and have effective sales conversations on the
											go. Experience the power of Google mail and Office 365 in your easy-to-use
											<a href={"https://blog.peppercloud.com/introducing-crm-inbox/"}> CRM inbox</a>.
										</>
									}
									header="h2"
									img={syncEmailsIcon}
									title="Nurture better relationships with 2-way email sync"
								/>
								<Box mb={4} mt={4}>
									<Divider light />
								</Box>
								{syncEmails.map((each, index) => (
									<div key={each.title}>
										<Box my={1} p={2}>
											<Box
												alignItems="center"
												display="flex"
												justifyContent="space-between"
												onClick={() => setActiveSyncEmails(index === activeSyncEmails ? -1 : index)}
											>
												<Box color="text.secondary" component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
													{each.title}
												</Box>
												<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
													{index !== activeSyncEmails ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
												</IconButton>
											</Box>
											<Collapse in={activeSyncEmails === index}>
												<ParagraphTypography fontSize={16} mr={6} mt={2}>
													{each.description}
												</ParagraphTypography>
											</Collapse>
										</Box>
										<Divider light />
									</div>
								))}
								<br />
								<br />
								<Box textAlign={{ xs: "center", sm: "center" }[width]}>
									<Button color="secondary" component={Link} size="large" to="/contact" variant="contained">
										Start to sync
									</Button>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} sm={12}>
						<Box mt={2} pl={2} textAlign="right">
							<Box
								alt="Representation of Gmail and Outlook integration with cloud-based CRM"
								className={classes.img}
								component="img"
								src={twoWaySyncImg}
								width="520px !important"
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Box mb={3} mt={8}>
				<Divider light />
			</Box>
			<Grid alignItems="center" container justify="center">
				<Grid item md={6} sm={12}>
					<Box mt={2} pl={2} pr={6} textAlign="right">
						<Box
							alt="Visual of Gmail inbox after installing CRM Chrome extension"
							className={classes.img}
							component="img"
							src={workFromEmailImg}
							// mr={typeof { sm: 0, xs: 0 }[width] === undefined ? 2 : { sm: 0, xs: 0 }[width]}
							// mr={2}
							width="100%"
						/>
					</Box>
				</Grid>
				<Grid item md={6} sm={12}>
					<Box display="flex" justifyContent={{ sm: "center", md: "flex-start" }}>
						<Box maxWidth={650} mt={4} p={1}>
							<AccordionHeader
								alt="Powerful CRM integration"
								description={
									<>
										Install our CRM{" "}
										<a href="https://chrome.google.com/webstore/detail/pepper-cloud-crm-chrome-e/iccmmindhfioainhgehkhdcmapohogff">
											{" "}
											Google chrome extension
										</a>{" "}
										and instill your CRM account right in your inbox. Manage contacts, sales emails, and opportunities
										round the clock without the hassle of tab hopping.
									</>
								}
								header="h2"
								img={workFromEmailIcon}
								title="Google chrome extension for more convenience"
							/>
							<Box mb={4} mt={4}>
								<Divider light />
							</Box>
							{workFromEmail.map((each, index) => (
								<div key={each.title}>
									<Box my={1} p={2}>
										<Box
											alignItems="center"
											display="flex"
											justifyContent="space-between"
											onClick={() => setActiveWorkFromEmail(index === activeWorkFromEmail ? -1 : index)}
										>
											<Box color="text.secondary" component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
												{each.title}
											</Box>
											<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
												{index !== activeWorkFromEmail ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
											</IconButton>
										</Box>
										<Collapse in={activeWorkFromEmail === index}>
											<ParagraphTypography fontSize={16} mr={6} mt={2}>
												{each.description}
											</ParagraphTypography>
										</Collapse>
									</Box>
									<Divider light />
								</div>
							))}
							<br />
							<br />
							<Box textAlign={{ xs: "center", sm: "center" }[width]}>
								<Button
									color="secondary"
									href="https://chrome.google.com/webstore/detail/pepper-cloud-crm-chrome-e/iccmmindhfioainhgehkhdcmapohogff"
									rel="noreferrer"
									size="large"
									target="_blank"
									variant="contained"
								>
									Get your chrome extension
								</Button>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Box mb={3} mt={8}>
				<Divider light />
			</Box>
			<Container>
				<Grid
					alignItems="center"
					container
					direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
					justify="center"
				>
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent={{ sm: "center", md: "flex-start" }}>
							<Box maxWidth={620} p={1}>
								<AccordionHeader
									alt="Quick CRM integration"
									description={
										<>
											Keeping your contacts up-to-date is made easier with the{" "}
											<a href="https://peppercloud.com/"> best sales CRM of Singapore</a>. Integrate your Google
											contacts or Office 365 contacts with CRM software and reach out to your customer with utmost ease.
										</>
									}
									header="h2"
									img={syncContactIcon}
									title="Boost sales productivity with contact import"
								/>
								<Box mb={2} mt={3}>
									<Divider light />
								</Box>
								{syncContact.map((each, index) => (
									<div key={each.title}>
										<Box my={1} p={2}>
											<Box
												alignItems="center"
												display="flex"
												justifyContent="space-between"
												onClick={() => setActiveSyncContact(index === activeSyncContact ? -1 : index)}
											>
												<Box component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
													{each.title}
												</Box>
												<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
													{index !== activeSyncContact ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
												</IconButton>
											</Box>
											<Collapse in={activeSyncContact === index}>
												<ParagraphTypography fontSize={16} mr={6} mt={2}>
													{each.description}
												</ParagraphTypography>
											</Collapse>
										</Box>
										<Divider light />
									</div>
								))}
							</Box>
						</Box>
					</Grid>
					<Grid item md={1} sm={12} />
					<Grid item md={5} sm={12}>
						<img
							alt="Representation of contact import from Gmail and Outlookthrough CRM system integration"
							className={classes.img}
							src={inboxImg3}
						/>
					</Grid>
				</Grid>
			</Container>
			<br />
			<br />
			<br />
			<br />
			<br />
			<Box className={classes.integrationsFeatures} py={{ sm: 6, xs: 4 }[width] || 12}>
				<Container>
					<Box textAlign="center">
						<HeaderTypography color="common.white" component="h2" fontSize={40} fontWeight={700} mb={6} mt={0}>
							Manage your sales emails right from your CRM
						</HeaderTypography>
					</Box>
					<Box>
						<Grid container spacing={6}>
							<Grid item md={4} sm={4} xs={12}>
								<div className={classes.integrationFeatureItem}>
									<Box
										alt="Pen icon that indicates organisation with email integration "
										component="img"
										mt={2}
										src={featureOrganize}
										width={50}
									/>
									<HeaderTypography component="h3" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
										Organise
									</HeaderTypography>
									<ParagraphTypography color="text.paragraph" fontSize={16} m={0}>
										No more sifting numerous emails. Organise your emails in a clutter-free CRM inbox.
									</ParagraphTypography>
								</div>
							</Grid>
							<Grid item md={4} sm={4} xs={12}>
								<div className={classes.integrationFeatureItem}>
									<Box
										alt="Hourglass icon to indicate time saving CRM system integration"
										component="img"
										mt={2}
										src={featureUnderstand}
										width={50}
									/>
									<HeaderTypography component="h3" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
										Understand
									</HeaderTypography>
									<ParagraphTypography color="text.paragraph" fontSize={16} m={0}>
										With easy access to the complete email history of leads, know the context for communication.
									</ParagraphTypography>
								</div>
							</Grid>
							<Grid item md={4} sm={4} xs={12}>
								<div className={classes.integrationFeatureItem}>
									<Box
										alt="Icon indicating the transformation of your sales process"
										component="img"
										mt={2}
										src={featureTrasform}
										width={50}
									/>
									<HeaderTypography component="h3" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
										Transform
									</HeaderTypography>
									<ParagraphTypography color="text.paragraph" fontSize={16} m={0}>
										Revamp your email interactions with the hassle-free, intuitive CRM inbox.
									</ParagraphTypography>
								</div>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
			<Box component={Container} mb={3} textAlign="center">
				<Box py={8}>
					<HeaderTypography
						alignItems="center"
						component="h2"
						display="flex"
						fontSize={40}
						justifyContent="center"
						lineHeight={1.2}
						mb={2}
						mt={0}
					>
						Our happy customers are accelerating their sales growth
					</HeaderTypography>
					<Box display="flex" justifyContent="center">
						<ParagraphTypography maxWidth={720}>
							Many businesses trust Pepper Cloud CRM software to empower their sales team and run their operations
							globally. Know about our clients&apos; experiences in their words.
						</ParagraphTypography>
					</Box>
				</Box>
				<Container maxWidth="lg">
					<SingleTestimonial {...client} />
				</Container>
			</Box>
			<Box className={classes.requestDemoRoot} px={2} py={{ sm: 10, xs: 8 }[width] || 12}>
				<HeaderTypography color="common.white" fontSize={38} m={0} textAlign="center">
					Every idea begins with a small step
				</HeaderTypography>
				<ParagraphTypography color="common.white" fontSize={24} mt={0} textAlign="center">
					Are you ready to take your step?
				</ParagraphTypography>
				<Box mt={6} textAlign="center">
					<WhiteButton color="primary" component={Link} size="large" to="/contact" variant="contained">
						{`Let's do this!`}
					</WhiteButton>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default Inbox;
